var pawLibrary = {
	map: false,
	fbAccessToken: "1046599778716357|Ru5dCXFhXsqfy2-ziNbyZEGmOhs",
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	fullMonthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	formatAlbumTitle: function(title)
	{
		return title;
	},
	formatTitleAndDate: function(title) {
		title = this.formatAlbumTitle(title);
		var pattern = /(\d{2}(-|\/)\d{2}(-|\/)\d{2,4})/g;
		var date = title.match(pattern);

		if(date != null) {
			var name = title.replace(date[0],"");
			date = date[0].split(/(-|\/)/g);
			if(date[4].length == 2) {
				date[4] = "20" + date[4];
				date[4] = parseInt(date[4]);
			}
			return { name: name.trim(), date: date[0] + ' ' + this.monthNames[date[2]-1] + ' ' + date[4] };
		} else {
			var name = title;
			return { name: name.trim(), date: "" };
		}
	},
	populateFacebookGallery: function(screenname, albumId)
	{
		var postsURL = "https://graph.facebook.com/" + albumId + "?fields=name,photos{images,link},created_time&access_token=" + this.fbAccessToken;
		var target = document.querySelector('#gallery');
		var loader = $('#loader');

		$.ajax({
				url: postsURL,
				method: 'GET',
				dataType: "jsonp",
				success: function (data)
				{
					loader.hide();
					var title = data.name;

					$('#fbTitle').html(title);
					$('title').html(title);

					$.each(data.photos.data, function(key,row) {
						var img = row.images[3].source;
						var link = row.link;
						var item = document.createElement('a');
								item.setAttribute("class", "item");
								item.setAttribute("href", link);
						var template = document.createElement('img');
								template.setAttribute("src", img);

						item.appendChild(template);
						salvattore.appendElements(target,[item]);
					});

				},
				error: function(status) {
						console.log("Facebook data could not be retrieved.  Failed with a status of " + status);
				}
		});
	},

	populateFacebookUpdates: function(screenname)
	{
		var postsURL = "https://graph.facebook.com/" + screenname + "?fields=feed.limit(2){message}&limit=1&access_token=" + this.fbAccessToken;
		 var target = $('#fbFeed');
		 var truncate = 230;

		 if(target.length > 0)
		 {
			 target.html("<div class=\"center\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>");
			 $.ajax({
					 url: postsURL,
					 method: 'GET',
					 dataType: "jsonp",
					 success: function (data)
					 {
						 if(data.feed.data[0]['message']) {
							 	var msg = data.feed.data[0]['message'];
						 } else {
							 	var msg = data.feed.data[1]['message'];
						 }

						 if(msg.length > truncate) {
							  msg = msg.substring(0,truncate) + "...";
						 }
						 target.html(msg);
					 },
					 error: function(status) {
							 console.log("Facebook data could not be retrieved.  Failed with a status of " + status);
					 }
			 });
		 }
	},
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});

		
		// hide mega menu
		$(document).click(function(e) {
			if($('ul#nav li.parent a').hasClass('highlight') == true) {
				var $container = $("header#page-header .btm");
		    if (!$container.is(e.target) && $container.has(e.target).length === 0) {
		       $('ul#nav li.parent a.highlight').trigger('click');
		    }
			}
		});
		
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 9,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});
		
		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},
	
	isValidEmailAddress: function(emailAddress) {
		var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
		return pattern.test(emailAddress);
	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( $('#mobile-burger-btn').length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if(status == false)
				{
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					target.slideUp(function() {
						target.removeClass('open');
					});
				}
			});
		}

		
		/*
		$('#mobile-nav-inner-items').hcSticky({
			top:0
		});
		*/


		// d$("#mobile-nav-inner-items div").stick_in_parent();
	

		// SEARCH BTN
		var search_frm = $('#search-frm');
		var search_btn = $('#search-btn');
		search_btn.click(function() {
			if(search_frm.hasClass('active') == true) {
				search_btn.removeClass('active');
				search_frm.slideUp(function() {
					search_frm.removeClass('active');
				});
			} else {
				search_btn.addClass('active');
				search_frm.slideDown(function() {
					search_frm.addClass('active');
				});
			}
		});

	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function()
	{
		var btn = $('#request');

		if(btn.length > 0) {
			btn.fancybox({
				afterLoad: function() {
					var url = window.location.href;
							url = url.split('#');
							url = url[0];

					var tmp = $('#productTitle').html();

					$('#url_txt').val(url);
					$('#title_txt').val(tmp);
				},
				afterShow: function( instance, slide ) {
					var loading = $('#loader');
					var form = $('#enquiry-frm');

					var name_txt = form.find('#name_txt');
					var email_txt = form.find('#email_txt');
					var msg_txt = form.find('#msg_txt');

					var name_err = form.find('#error-name');
					var email_err = form.find('#error-email');
					var msg_err = form.find('#error-msg');

					$('#send_msg_btn').click(function(e) {
						e.preventDefault();
						var errors = false;

						// validation
						if(name_txt.val() == '') {

							name_txt.addClass('error-input');
							name_err.fadeIn();
							errors = true;
						} else {
							name_txt.removeClass('error-input');
							name_err.hide();
						}
						if(!pawLibrary.isValidEmailAddress(email_txt.val())) {
							email_txt.addClass('error-input');
							email_err.fadeIn();
							errors = true;
						} else {
							email_txt.removeClass('error-input');
							email_err.hide();
						}
						if(msg_txt.val() == '') {
							msg_txt.addClass('error-input');
							msg_err.fadeIn();
							errors = true;
						} else {
							msg_txt.removeClass('error-input');
							msg_err.hide();
						}

						// sent
						if(errors == false) {
							loading.removeClass('hide').fadeIn();
							form.hide();
							var formData = $('#enquiry-frm').serialize();
							
							$.ajax({
								url: './ajax/sendEnquiry.inc.php?init=sendEnquiry',
								data: formData,
								method: 'POST'
							}).done(function(response) {
								$('#loader').html("Your message has been sent");
							});
						
						}
					});
				}
			});
		}
	},

	onResize: function()
	{

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	products: function() {

	},

	setMinorTabs: function() {
		if($('#minor-tabs').length > 0) {
			var tabs = $('#minor-tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			btns.unbind().click(function(e) {
				var selected = $(this).attr('id').replace("btn_", "");
				if(current != selected) {
					// selected tab
					btns.removeClass('selected');
					$('#btn_' + selected).addClass('selected');
					// selected textarea
					$('#item_' + current).addClass('hidden');
					$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
					// updated current
					current = selected;
				}
			});

		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setTabs: function() {
		if($('#tabs').length > 0) {
			var tabs = $('#tabs-btn span');
			var tabsContent = $('.tab-content-inner');
			tabs.click(function() {
				var selected = $(this).attr('data-tab-btn');
				tabs.removeClass('selected');
				$('*[data-tab-btn="' + selected + '"]').addClass('selected');
				//
				tabsContent.hide().addClass('hidden');
				$('#tab' + selected + '-txt').removeClass('hidden').fadeIn();
			});


			var topLevel = $('a.toggle');
			topLevel.click(function(e) {
				e.preventDefault();
				var $this = $(this);
				topLevel.removeClass('active');
				if ($this.next().hasClass('show')) {
						$this.next().removeClass('show');
						$this.next().slideUp(350);
				} else {
						$this.parent().parent().find('li .inner').removeClass('show');
						$this.parent().parent().find('li .inner').slideUp(350);
						$this.next().toggleClass('show');
						$this.next().slideToggle(350);
						$($this[0]).addClass('active');
				}
			});
		}

	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	facebookGalleries: function(screenname)
	{
		var postsURL = "https://graph.facebook.com/" + screenname + "?fields=albums{name,photos.limit(1){images{source}},created_time}&access_token=" + this.fbAccessToken;
 		var target = $('#fbGalleries');
		var exclude = ["Timeline Photos", "Cover Photos", "Mobile Uploads", "Profile Pictures"];
		var dropdowns = [];

		target.html(this.loading);

		$.ajax({
			url: postsURL
		}).done(function(response) {
			var albums = response.albums.data;
			target.html("");
			$.each(albums, function(key,row) {
					var index = exclude.indexOf(row.name);
					if(index < 0)
					{
						var img = (row.photos) ? row.photos.data[0].images[3].source : "";
						var url = './gallery/view/' + row.id + "/" + row.name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
						if(img != false)
						{
							var template = '<li>';
									template += '<a href="' + url + '" style="background-image:url(\'' + img + '\')">';
										template += '<span class="overlay"></span>';
										template += '<span class="title">';
											template += '<span class="inner">';
										 		template += row.name;
											template += '</span>';
										template += '</span>';
										template += '<span class="btn">View More</span>';
									template += '</a>';
								template += '</li>';
						target.append(template);
					}
				}
			});

		});
	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	productGallery: function() {
		if( $('#thumbs').length > 0 ) {
			$('#gallery').on('cycle-next cycle-prev', function(e, opts) {
				$('#thumbs').cycle('goto', opts.currSlide);
			});

			$('#thumbs span.thumb-wrap').click(function(){
				var index = $('#thumbs').data('cycle.API').getSlideIndex(this);
				$('#gallery').cycle('goto', index);
			});
		}
		
	},

	videoGallery: function() {
		if( $('#video-thumbs').length > 0 ) {
			$('#video-gallery').on('cycle-next cycle-prev', function(e, opts) {
				$('#video-thumbs').cycle('goto', opts.currSlide);
			});

			$('#video-thumbs span.thumb-wrap').click(function(){
				var index = $('#video-thumbs').data('cycle.API').getSlideIndex(this);
				$('#video-gallery').cycle('goto', index);
			});
		}
		
	},

	brandcarousel: function()
	{
		var brandcarousel = $('.brandcarousel');
		if(brandcarousel.length > 0) {
			brandcarousel.slick({
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 3,
					speed: 500,
					autoplay: true,
					nextArrow: '.slick-next',
					prevArrow: '.slick-prev',
					responsive: [{
						breakpoint: 500,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
					]
			});
		}
	},

	filterProducts: function() {
		var brands_sel = $('#brands_sel');
		var makes_sel = $('#makes_sel');
		var zeroResults = $('#no-results-wrap');
		
		var products = $('ul#listProducts');
		var all_products = products.clone();
		all_products.attr('id', 'listProducts-all');
		all_products.addClass('hide');
		all_products.appendTo('body');

		zeroResults.find('span').click(function() {
			brands_sel.val(0);
			makes_sel.val(0);
			$('#filter-dropdown select').trigger('change');
		});

		$('#filter-dropdown select').on('change', function() {
			zeroResults.addClass('hide');
			var selected_brand = brands_sel.val();
			var selected_make = parseInt(makes_sel.val());
			all_products = $('#listProducts-all').clone();
			products.empty();
			var items = all_products.find('li');
			$.each(items, function(key,row) {
				if((selected_make == 0) && (selected_brand == 0)) {
					products.append(row);
				} else {
					var found = true;
					if(selected_brand > 0) {
						if($(row).attr('data-brand-id') != selected_brand) {
							found = false;
						}
					}
					if(selected_make > 0) {
						var selected_make_title = $('#makes_sel option[value="' + selected_make + '"]').text();
						var product_item_makes = $(row).attr('data-make-id').split(" ");
						
						$.each(product_item_makes, function(tmpMakeId,tmpMakeRow) {
							product_item_makes[tmpMakeId] = parseInt(tmpMakeRow);
						});

						if($.inArray(selected_make, product_item_makes) == -1) {
							found = false;
						}

						/*
						var isParent = (selected_make_title.indexOf(' » ') == -1) ? true : false;
						if(isParent == true) {
							if($(row).attr('data-make-parent') != selected_make) { // SEARCH TOP TEIR
								found = false;
							}
						} else {
							if($(row).attr('data-make-id') != selected_make) { // SEARCH SUB TEIR
								found = false;
							}
						}
						*/
					}

					if(found == true) {
						products.append(row);
					}
				}

			});

			// COUNT ITEMS
			var results = $('#listProducts li').length;
			if(results == 0) {
				zeroResults.removeClass('hide');
			}
			
		});

	},


	cartesianProductOf: function(arrays) {
		if (!arrays || arrays.length < 1)
				return [];
		else {
				var header = arrays[0];
				var row = arrays.slice(1);
				var result = [];
				for (var i = 0; i < header.length; i++) {
						var productOfRow = this.cartesianProductOf(row);
						if (productOfRow && productOfRow.length > 0) {
								for (var j = 0; j < productOfRow.length; j++) {
										result.push([header[i]].concat(productOfRow[j]));
								}
						}
						else
								result.push([header[i]]);
				}
				return result;
		}
},

ajaxPricingFilter: function(productId,options)
{
	var dropdowns = $('.filterCats');

	var allVariants = $('#options_sel option');
	var postdata = {};
	var priceId = 0;

	dropdowns.unbind();
	dropdowns.change( function() {

		var add_to_cart_btn = $('.add_to_cart');

		// FILTER DROPDOWNS
		var updated_dropdown_id = $(this).attr('id').replace('variant_sel_', '');
		var updated_dropdown_select = $(this).val();
		var num_of_variants = dropdowns.length;

		// RESTORE DROPDOWNS
		$.each(dropdowns, function(key,row)
		{
			if(key != updated_dropdown_id)
			{
				var tmp_selected = $('#variant_sel_wrap' + key + " option:selected").val();
				$('#variant_sel_wrap' + key).html( $(pawLibrary.productDropdowns[key]).html() );
				$('#variant_sel_wrap' + key + " select").val(tmp_selected);	
			}
		});

		// FILTER INVALID
		var search_options = new Array();
		var x = 0;

		// GET ALL OPTIONS
		$.each(dropdowns, function(key, row) {
			search_options[key] = new Array();
			if(key != updated_dropdown_id)
			{
				var row_options = $(row).children('option');
				$.each(row_options, function(subId,sub) {
					search_options[key][subId] = sub.value;
				});
			} else {
				search_options[key][0] = $(dropdowns[updated_dropdown_id]).val();
			}
		});

		search_options = pawLibrary.cartesianProductOf(search_options);
		search_options_valid = new Array();


		// REMOVE INVALID
		var x = 0;
		$.each(search_options, function(key,row) {
			var tmp = row.join(' / ');
			var found = false;

			$.each(allVariants, function(varId,varVal) {
				var varVal = $(varVal).text();
				if(varVal == tmp)
				{
					found = true;
				}
			});

			if(found == true) {
				search_options_valid[x] = tmp;
				x++;
			}

		});

		// PAIR WITH DROPDOWNS
		var allDropdowns = $('#ajaxTarget option');
		var x = 0;
		$.each(dropdowns, function(key, row) {
			if(key != updated_dropdown_id)
			{
				var row_options = $(row).children('option');
				$.each(row_options, function(tmpVal,tmpOption)
				{
					var tmp_txt = $(tmpOption).text().replace(' - ', '');
					var tmp_color = tmp_txt;

					// LOOK FOR OPTION
					var found = false;

					$.each(search_options_valid, function(optId,optVal) {
						var optVal = optVal.split(' / ');
							optVal = optVal[key];
						if(optVal == tmp_txt) {
							found = true;
						}
					});

					if(found == false)
					{
						var tmp_value = $(tmpOption).val();
						$("#ajaxTarget #variant_sel_"+key+" option[value='"+tmp_value+"']").remove();
					}
					x++;
				});
			}
		});


		// SEARCH PRICE AND THUMB
		var search_for_price = '';
		var search_for_thumb = '';
		$.each(dropdowns, function(key, value) {
			selected = $(value).val();
			search_for_price += selected + ' / ';
		});

		if(search_for_price != '')
		{
			search_for_price = search_for_price.substring(0, (search_for_price.length - 3));

			$.each($('#options_sel option'), function(key,row) {
				var row = $(row);
				var tmp_txt = row.text();
				if(tmp_txt == search_for_price)
				{
					priceId = row.val();
				}
			});

			search_for_thumb = search_for_price;
		}

		// SEARCH FOR PRODUCT DETIALS
		if(priceId > 0)
		{
			var target = $('#js-price-details-'+priceId);
			options.attr('style', 'display:none');
			options.removeClass('selected');
			if(target.length > 0) {
				target.show();
				target.addClass('selected');
			} else {
				target.hide();
			}

			$('#cat_sel').val(priceId);
		}

		// HIDE SELECTED
		$.each(dropdowns, function(key,row) {
			var options = $(row).children('option');
			$.each(options, function(optId,opt) {
				var optObj = $(opt);
			});
		})


		pawLibrary.ajaxPricingFilter(productId,options);

	});
},

changePrice: function()
{

	var dropdown = $('#options_sel');
	var options = $('.price-value');
	var productId = $('#product_sel').val();

	$('#options_sel').hide();

	$('#ajaxTarget').load('./ajax/productOptions.inc.php?init=setProductOptions&id=' + productId, function() {
		pawLibrary.productDropdowns = $('.variant-wrap').clone();

		pawLibrary.ajaxPricingFilter(productId,options); // TRIGGER FIRST LOAD

		var setup_dropdown = $('.filterCats');
		var total_dropdowns = setup_dropdown.length;
		if(setup_dropdown.length > 0)
		{
			$.each(setup_dropdown, function(key,row) {
				if(key > 0)
				{
					$(row).trigger('change');
				}
			});
		}

	});

},

navigationAutocompleteResults: function(direction) {
	var target = $('#search-result ul');
	var index = target.find('li.highlight');

	if(index.length == 0) {
		pawLibrary.highlight = 1;
		target.find('li:nth-child(1)').addClass('highlight');
	} else {
		pawLibrary.highlight = (direction == 40) ? pawLibrary.highlight + 1 : pawLibrary.highlight - 1;
		$('#search-result ul li').removeClass('highlight');
		if(pawLibrary.highlight == 0) {
			pawLibrary.highlight = 0;
		} else {
			target.find('li:nth-child(' + (pawLibrary.highlight) + ')').addClass('highlight');
		}
	}

	//target.find('ul li:nth-child(' + (index + 1) + ')').addClass('highlight');
},

	productAutocompleteSearch: function(term, event) {
		var target = $('#search-result');
		var searchFrm = $('#search-frm');

		searchFrm.submit(function (e) {
			e.preventDefault();
		});

		if((event.keyCode == 38) || (event.keyCode == 40)) {
			// NAVIGATE
			pawLibrary.navigationAutocompleteResults(event.keyCode);
		} else if(event.keyCode == 13) {
			// REDIRECT TO A PRODUCT PAGE IF ONE IS SELECTED.  OTHERWISE REDIRECT TO SEARCH RESULT
			var url = "";
			var target = $('#search-result ul li.highlight');
			var base = $('base').attr('href');
			if(target.length == 1) {
				url = target.find('a').attr('href').replace("./", "");
			} else {
				url = searchFrm.attr('action').replace("./", "");
				url += "?search_txt=" + encodeURI(term);
			}
			window.location.href = base + url;
		} else {
			// IF TERM IS LONGER THAN 3 LETTERS .. SCAN DATATABLE
			if(term.length > 3) {
				$.ajax({
					url: './ajax/products.inc.php?init=products&search=' + term
				}).done(function(response) {
					var data = $.parseJSON(response);
					if(data.length == 0) {
						target.empty();
					} else {
						var results = target.find('ul');
						if(results.length == 0) {
							target.append('<ul></ul>');
						}
	
						target.find('ul').empty();
	
						$.each(data, function(i, v) {
							var tmp = '<li>';
										tmp += '<a href="' + v.url + '">';
											tmp += '<span class="img-wrap">';
												tmp += '<img src="' + v.thumb.filename + '" alt="' + v.title + '" />';
											tmp += '</span>';
											tmp += '<span class="txt-wrap">' + v.title + '</span>';
										tmp += '</a>';
									tmp += '</li>';
							target.find('ul').append(tmp);
						});
					}
				});
			} else {
				target.empty();
			}
		}

	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.banners();
		pawLibrary.brandcarousel();
		pawLibrary.jumpToLinks();
		pawLibrary.dropdownMenus();
		pawLibrary.setTabs();

		if($('#search_txt').length == 1) {
			$('#search_txt').on('keyup', function(e) {
				e.preventDefault();
				pawLibrary.productAutocompleteSearch(this.value, e);
			});
		}

		if( $('.price-value').length > 0 ) {
			this.changePrice();
		}

		if(($('#brands_sel').length == 1) || ($('#makes_sel').length == 1)) {
			pawLibrary.filterProducts();
		}


		if($('#gallery').length > 0) {
			pawLibrary.productGallery();
		}


		if($('#video-gallery').length > 0) {
			pawLibrary.videoGallery();
		}


		/*
		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			fancyBox.fancybox({
				padding: 0
			});
		}

		var fancyMedia = $('.fancybox-media');
		if( fancyMedia.length > 0 ) {
			fancyMedia.fancybox({
				openEffect  : 'none',
				closeEffect : 'none',
				helpers : {
					media : {}
				}
			});
		}*/

		if( $('#fbGalleries').length > 0 )
		{
			pawLibrary.facebookGalleries("powermaxengineeringltd");
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

	}

}

$(window).load(function() {
	//$.noConflict();
	if( $('#request').length > 0 ) {
		$.noConflict();
	}
	pawLibrary.init();
	var banners = $("#slideshow-inner");
	if(banners.length > 0) {
		pawLibrary.setGallery(banners);
	}
});
